html {
    scroll-behavior: smooth;
}


.logoContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    flex-direction: column;
}

.logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.pclass {
    text-align: center;
    line-height: 1.5;
    font-size: 17px;
    font-weight: 500;
    /* border:1px solid black;  */
}

.copyright {
    font-size: 15;
    color: blue;
}

.wallImage {
    max-width: 900px;
    width: 100%;
}

.title_1 {
    font-size: 30px;
    font-weight: 500;
}

.title_2 {
    font-size: 30px;
    font-weight: 900px;
}


.mainHeaderBox {
    background: rgb(32, 18, 159);
    background: linear-gradient(103deg, rgba(32, 18, 159, 1) 7%, rgba(128, 20, 196, 1) 71%);
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: -1;
}

.mainHeaderBox .titleSection {
    padding: 10px;
    max-width: 1000px;
    margin: 180px auto 0px auto;
    text-align: center;
}

.mainHeaderBox .titleSection h1 {
    color: #fff;
    text-align: center;
    font-size: 60px;
    padding: 0;
    margin: 0;
}

.mainHeaderBox .titleSection p {
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-weight: 200;
}

.mainImageContainer {
    width: 100%;
    text-align: center;
    margin-top: -250px;
}

.contentContainer{
    background-color: #f2f2f2;
}