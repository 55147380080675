.navItem {
    padding: 10px;
    margin: 0 10px;
    color: #fff;
}


.navContentContainer {
    width: 100%;
    max-width: 960px;
    /* background-color: #fff; */
    margin: 0 auto;
    height: 80px;
    display: flex;
    flex-direction: row;
}


.navIconContainer {
    flex: 1;
    padding: 10px;
}

.imageStyle {
    border-radius: 10px;
}

.navMenuContainer {
    flex: 5;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}



.navContainer {
    height: 100px;
    padding: 10px;
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 100;
    transition: 1s;
}


.navbarActive{
    height: 80px;
    background-color: #fff;
    transition: 500ms In Out · Cubic;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}

.navbarActive p{
    color: black;
}